<template>
  <div class="card" style="max-height: 227.5px">
    <div class="card-header pb-0 px-3">
      <h6 class="mb-0">Contratos</h6>
    </div>
    <div class="card-body pt-4" style="overflow-y: auto">
      <ul class="list-group">
        <li
          v-for="(contrato, index) of ContratosStore.getContratos().registros"
          :key="index"
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-3 text-sm">{{ contrato.contrato }}</h6>
            <span class="mb-2 text-xs">
              Status contrato:
              <span v-if="contrato.status == 'A'" class="text-success font-weight-bold ms-sm-2">
                Ativo</span
              >
              <span v-else class="text-dark font-weight-bold ms-sm-2">
                Inativo</span
              >
            </span>
            <span class="mb-2 text-xs">
              Data ativação:
              <span class="text-dark ms-sm-2 font-weight-bold">
                {{ contrato.data_ativacao.split("-").reverse().join("/") }}</span
              >
            </span>
            <span class="text-xs">
              ID Contrato:
              <span class="text-dark ms-sm-2 font-weight-bold">{{ contrato.id }}</span>
            </span>
          </div>
          <div class="ms-auto text-end">
            <div v-if="contrato.status == 'A'" style="cursor:default" class="btn btn-link d-flex text-success px-3 mb-0">
              <i class="bi bi-emoji-smile me-2" aria-hidden="true"></i>
              <div class="text-success">
                Ativo
              </div>
            </div>
            <div v-else style="cursor:default" class="btn btn-link d-flex text-dark px-3 mb-0">
              <i class="bi bi-emoji-frown me-2" aria-hidden="true"></i>
              <div class="text-dark">
                Inativo
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { useContratosStore } from "../../stores/contratos";
let ContratosStore;

export default {
  name: "Contratos",
  data() {
    return {
      ContratosStore
    }
  },
  setup() {
    ContratosStore = useContratosStore();
  }
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

html {
  scrollbar-color: #888;
  scrollbar-width: thin;
}
</style>
