<template>
  <div class="container-fluid mt-4">
    <div class="row">
      <div class="col-lg-8">
        <div class="row mt-4">
          <div class="col-xl-6 mb-xl-0 mb-4">
            <CardCliente />
          </div>
          <div class="col-xl-6 mb-2">
            <div class="row d-none d-md-flex">
              <div class="col-md-6 mb-2">
                <default-info-card
                  class="box-hover"
                  @click="AbrirRota('/contratos')"
                  icon="text-white fas fa-file-contract"
                  title="Contratos"
                  description="Ativos"
                  :value="Number(ContratosStore.getTotalContratos())"
                  style="min-height: 227.5px"
                />
              </div>
              <div class="col-md-6 mb-1">
                <CardContatoFinanceiro
                  icon="text-white fab fa-whatsapp"
                  title="Financeiro"
                  description="Contato"
                  style="min-height: 227.5px"
                />
              </div>
            </div>

            <div class="row d-sm-flex d-md-none">
              <div class="col-md-6 mb-2">
                <CardNovoConexao
                  :value="Number(ContratosStore.getTotalContratos())"
                  :cor="true"
                  :text="'Contratos Ativos'"
                  :icon="'fas fa-file-contract'"
                />
              </div>
              <div class="col-md-6">
                <CardContatoDoFinanceiro
                    :text="'Contato Financeiro'"
                    :icon="'fab fa-whatsapp'"
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 mt-4">
        <Contratos ref="boxContratos" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-7 mt-2">
        <Boletos />
      </div>
      <div class="col-md-5 mt-2">
        <BoxNotas
          :card="{
            title: 'Notas',
          }"
        >
          <ListaNotas />
        </BoxNotas>
      </div>
    </div>
  </div>
</template>

<script>
import CardContatoDoFinanceiro from './components/CardContatoDoFinanceiro.vue';
import CardNovoConexao from "./components/CardNovoConexao.vue";
import setTooltip from "@/assets/js/tooltip.js";
import CardCliente from "./components/CardCliente.vue";
import DefaultInfoCard from "@/examples/Cards/DefaultInfoCard.vue";
import CardContatoFinanceiro from "./components/CardContatoFinanceiro.vue";
import Boletos from "./components/Boletos.vue";
import Contratos from "./components/Contratos.vue";
import BoxNotas from "./components/BoxNotas.vue";
import ListaNotas from "./components/ListaNotas.vue";
import { useAuthStore } from "../stores/auth";
import { useFinanceiroStore } from "../stores/financeiro";
import { useContratosStore } from "../stores/contratos";
import { useLoginStore } from "../stores/login";
import router from "../router";
let FinanceiroStore;
let ContratosStore;
let AuthStore;
let LoginStore;

export default {
  name: "Billing",
  components: {
    CardCliente,
    DefaultInfoCard,
    CardContatoFinanceiro,
    Boletos,
    Contratos,
    ListaNotas,
    BoxNotas,
    CardNovoConexao,
    CardContatoDoFinanceiro,
  },
  data() {
    return {
      ContratosStore,
      tamanhoBoxContratos: 0,
      AuthStore,
      FinanceiroStore,
      LoginStore,
    };
  },
  async mounted() {
    setTooltip();
    this.setarTamanho();
    /* await FinanceiroStore.AtualizaBoletosAbertos();
     await FinanceiroStore.AtualizaBoletosPagos();
     await ContratosStore.AtualizaContratos();
     await ContratosStore.AtualizaNotas();
     await LoginStore.AtualizaConsumoDiario();*/
  },
  methods: {
    AbrirRota(rota) {
      router.push(rota);
    },
    setarTamanho() {
      let tamanho = this.$refs.boxContratos.$el.clientHeight;
      this.tamanhoBoxContratos = tamanho + "px";
    },
  },
  setup() {
    ContratosStore = useContratosStore();
    AuthStore = useAuthStore();
    FinanceiroStore = useFinanceiroStore();
    LoginStore = useLoginStore();
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

html {
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;
}

.box-hover:hover {
  cursor: pointer;
}
</style>
