<template>
  <div class="card" style="min-height: 244px; max-height: 552px;">
    <div class="card-header pb-0 p-3" style="min-height: 57px;">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Boletos</h6>
        </div>
      </div>
    </div>
    <div class="card-body p-3 pb-0 mb-0"
      :class="!FinanceiroStore.getBoletosAbertos().financeiro && !FinanceiroStore.getBoletosPagos().financeiro ? 'd-flex justify-content-center align-items-center' : ''"
      style="overflow-y:auto; min-height: 244px; max-height: 552px;">
      <div v-if="FinanceiroStore.getBoletosAbertos().financeiro || FinanceiroStore.getBoletosPagos().financeiro">
        <ul class="list-group">
          <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
            v-for="(boleto, index) in FinanceiroStore.getBoletosAbertos().financeiro" :key="index">
            <div class="d-flex align-items-center">
              <button @click="BaixarPDF(boleto)"
                :class="ValidaAtrasado(boleto.data_vencimento) ? 'btn-outline-danger' : 'btn-outline-dark'"
                class="p-3 mb-0 btn btn-icon-only btn-rounded me-3 btn-sm d-flex align-items-center justify-content-center">
                <i class="bi" :class="ValidaAtrasado(boleto.data_vencimento) ? 'bi-file-earmark-x' : 'bi-file-earmark-minus' " style="font-size: 1.2rem"></i>
              </button>
              <div class="d-flex flex-column">
                <h6 class="mb-1 font-weight-bold text-sm"
                  :class="boleto.status_cobranca == 'P' ? 'text-danger' : 'text-dark'">
                  {{ ValidaAtrasado(boleto.data_vencimento) ? 'Atrasado' : 'Em aberto' }}
                </h6>
                <span class="text-xs">Vencimento: {{ boleto.data_vencimento.split("-").reverse().join("/") }}</span>
              </div>
            </div>
            <div class="d-flex align-items-center text-sm">
              R$ {{ boleto.valor }}
              <button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4" @click="BaixarPDF(boleto)">
                <i class="fas fa-file-pdf text-lg me-1" aria-hidden="true"></i>
                PDF
              </button>
              <button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4" @click="AbrirModalPix(boleto)">
                Pix
              </button>
            </div>
          </li>
        </ul>

        <ul class="list-group">
          <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
            v-for="(boleto, index) in FinanceiroStore.getBoletosPagos().financeiro" :key="index">
            <div class="d-flex align-items-center">
              <button
                class="p-3 mb-0 btn btn-icon-only btn-rounded me-3 btn-sm d-flex align-items-center justify-content-center btn-outline-success">
                <i class="bi bi-file-earmark-check" style="font-size: 1.2rem"></i>
              </button>
              <div class="d-flex flex-column">
                <h6 class="mb-1 text-darkgreen font-weight-bold text-sm">
                  Pago
                </h6>
                <span v-if="boleto.obs != ''" class="text-xs">Ref: {{ boleto.obs }}</span>
                <span v-else class="text-xs">Data Emissão: {{ boleto.data_emissao.split('-').reverse().join('/') }}</span>
              </div>
            </div>
            <div class="d-flex align-items-center text-sm">
              R$ {{ boleto.valor }}
            </div>
          </li>
        </ul>
      </div>
      <div v-else class="mb-2">
        <h6 class="mb-1 text-dark font-weight-bold text-sm">
          Você não possui faturas!
        </h6>
      </div>
    </div>
  </div>
  <Pix :modal="exibeModalPix" :boleto="boletoSelecionado" @close="FecharModalPix" />
  <Loading :active='isLoading' :is-full-page="fullPage" :loader='loader' :color="colorLoading" />
</template>

<script>
import { api } from "../../boot/axios";
import { ref } from "vue";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import Pix from "./Pix.vue";

import { useFinanceiroStore } from "../../stores/financeiro";
let FinanceiroStore;

export default {
  name: "InvoiceCard",
  data() {
    return {
      FinanceiroStore,
      exibeModalPix: false,
      boletoSelecionado: undefined,
    }
  },
  components: {
    Loading,
    Pix,
  },
  methods: {
    ValidaAtrasado(dataVencimento) {
      const today = new Date();
      const partesData = dataVencimento.split('-');
      const ano = parseInt(partesData[0]);
      const mes = parseInt(partesData[1]) - 1;
      const dia = parseInt(partesData[2]);

      const vencimento = new Date(ano, mes, dia);
      today.setHours(0, 0, 0, 0);

      return vencimento < today;
    },
    async AbrirModalPix(boleto) {
      this.isLoading = true
      await api
        .post("financeiro/pixboleto", { "IdBoleto": boleto.id })
        .then((response) => {
          if (response.data.status == false) {
            this.$notify({ type: "error", text: response.data.msg + " Entre em contato com o setor financeiro!", duration: 7000 });
          } else {
            this.boletoSelecionado = boleto;
            this.exibeModalPix = true;
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
    FecharModalPix() {
      this.exibeModalPix = false;
    },
    BaixarPDF(boleto) {
      this.isLoading = true
      api
        .post("financeiro/pdfboleto", { "IdBoleto": boleto.id })
        .then((response) => {
          if (response.status == 401) {
            console.log(JSON.stringify(response.data.err.data));
          } else {
            var data = Uint8Array.from(atob(response.data.BoletoBase64), c => c.charCodeAt(0));
            const blob = new Blob([data], { type: "octet/stream" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = boleto.id + ".pdf";
            link.click();
            URL.revokeObjectURL(url);
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
    CopiarPix(boleto) {
      this.isLoading = true
      api
        .post("financeiro/pixboleto", { "IdBoleto": boleto.id })
        .then((response) => {
          if (response.data.status == false) {
            this.$notify({ type: "error", text: response.data.msg + " Entre em contato com o setor financeiro!", duration: 7000 });
          } else {
            const tempElement = document.createElement('textarea');
            tempElement.value = response.data.DadosPix.qrCode.qrcode;

            document.body.appendChild(tempElement);
            tempElement.select();
            document.execCommand('copy');
            document.body.removeChild(tempElement);
            this.$notify({ type: "success", text: "Código PIX copiado para área de transferência.", duration: 5000 });
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
  },
  setup() {
    FinanceiroStore = useFinanceiroStore();
    let isLoading = ref(false)
    let fullPage = true
    let loader = 'spinner'
    let colorLoading = '#007BFF'

    return {
      isLoading,
      fullPage,
      loader,
      colorLoading,
    }
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

html {
  scrollbar-color: #888;
  scrollbar-width: thin;
}

.text-darkgreen {
  color: #089308;
}
</style>
