<template>
    <div class="container-contratos mb-2 h-100" @click="ContatoWhatsapp">
      <div class="card-status h-100">
        <div
          class="icon icon-shape icon-lg shadow text-center border-radius-lg text-white bg-gradient-success"
        >
          <i class="opacity-10" :class="icon" aria-hidden="true"></i>
        </div>
  
        <div class="mx-3 informacoes">
          <p class="mb-0 text-center text-bold">{{ text }}</p>
          <h6 class="mb-0 text-bold" style="margin-left: 4px !important">
            <i class="opacity-10 fab fa-whatsapp text-bold" aria-hidden="true"></i>
            (51) 99587-9656
          </h6>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import SoftAvatar from "@/components/SoftAvatar.vue";
  import img from "@/assets/img/curved-images/curved14.jpg";
  import img1 from "@/assets/img/logos/mastercard.png";
  import logo from "@/assets/img/logo.png";
  
  export default {
    name: "CardContatoDoFinanceiro",
    components: {
      SoftAvatar,
    },
    props: {
      icon: String,
      text: String,
    },
    data() {
      return {
        img,
        img1,
        logo,
      };
    },
    methods: {
      ContatoWhatsapp() {
        window.open("https://api.whatsapp.com/send?phone=5551995879656", "_blank");
      },
    },
  };
  </script>
  
  <style scoped>
  .container-contratos {
    display: flex;
    margin: 0 auto;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }
  
  .container-contratos:hover {
      cursor: pointer;
  }
  
  .card-status {
    background-color: #fff;
    padding: 20px;
    width: 100%;
    display: flex;
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 1rem;
    align-items: center;
  }
  
  .badge-contrato {
    color: #fff;
  }
  
  .badge-contrato #badge-internet {
    max-width: auto;
    background-color: rgba(79, 148, 239, 0.2);
    color: rgb(79, 148, 239);
    padding: 2px 6px;
    border-radius: 12px;
  }
  
  .card-status p {
    margin-bottom: 0;
  }
  
  .informacoes {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: auto;
  }
  </style>
  