<template>
  <div class="card" style="min-height: 244px; max-height: 552px;">
    <div class="p-3 pb-0 card-header" style="min-height: 57px;">
      <div class="row justify-content-between">
        <div class="col-4 d-flex align-items-center">
          <h6 class="mb-0">{{ card.title }}</h6>
        </div>
        <div class="col-8 d-flex justify-content-end align-items-center">
          <div class="d-flex align-items-center">
            <select class="form-select" style="padding-right: 30px !important" v-model="ContratoSelecionado" aria-label="Default select example">
              <option value="undefined" selected>Selecionar Contrato</option>
              <option :value="contrato.id" :key="contrato.id_contrato"
                v-for="contrato in ContratosStore.getContratos().registros">ID: {{ contrato.id }} {{ contrato.endereco }} {{ contrato.numero }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="p-3 pb-0 mb-0 card-body"
      :class="ContratosStore.getNotas().length == 0 ? 'd-flex justify-content-center align-items-center' : ''"
      style="overflow-y:auto; min-height: 244px; max-height: 552px;">
      <ListaNotas v-if="ContratosStore.getNotas().length > 0" :title="card.subtitle" :item="item"
        :horizontal-break="horizontalBreak" />
      <div v-else-if="ContratoSelecionado == undefined" class="mb-2">
        <h6 class="mb-1 text-dark font-weight-bold text-sm">
          Selecione um contrato!
        </h6>
      </div>
      <div v-else class="mb-2">
        <h6 class="mb-1 text-dark font-weight-bold text-sm">
          Este contrato não possui notas!
        </h6>
      </div>
    </div>
  </div>
  <Loading :active='isLoading' :is-full-page="fullPage" :loader='loader' :color="colorLoading" />
</template>

<script>
import { ref } from 'vue';
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import ListaNotas from "./ListaNotas.vue";
import { useContratosStore } from "../../stores/contratos";
let ContratosStore;

export default {
  name: "RankingListCard",
  components: {
    ListaNotas,
    Loading,
  },
  props: {
    card: {
      type: Object,
      title: String,
      subtitle: String,
      date: String,
      default: () => { },
    },
    item: {
      type: Array,
      default: () => { },
      title: String,
      date: String,
      amount: String,
      icon: String,
      color: String,
    },
    horizontalBreak: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      ContratosStore,
      ContratoSelecionado: undefined,
    }
  },
  watch: {
    async ContratoSelecionado() {
      this.isLoading = true;
      await ContratosStore.AtualizaNotas(this.ContratoSelecionado)
      this.isLoading = false;
    }
  },
  setup() {
    ContratosStore = useContratosStore();
    let isLoading = ref(false)
    let fullPage = true
    let loader = 'spinner'
    let colorLoading = '#007BFF'

    return {
      isLoading,
      fullPage,
      loader,
      colorLoading,
    }
  }
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

html {
  scrollbar-color: #888;
  scrollbar-width: thin;
}
</style>
