<template>
  <h6 v-if="title" class="text-uppercase text-body text-xs font-weight-bolder mb-3 pt-2">
    {{ title }}
  </h6>
  <div v-if="ContratosStore.getNotas()">
    <ul class="list-group">
      <li v-for="(nota, index) in ContratosStore.getNotas()" :key="index"
        class="pb-0 border-0 list-group-item justify-content-between ps-0 border-radius-lg">
        <div class="d-flex">
          <div class="d-flex align-items-center">
            <button @click="ExibeModalNotas(nota.notas)"
              class="p-3 mb-0 btn btn-icon-only btn-rounded me-3 btn-sm d-flex align-items-center justify-content-center btn-outline-success">
              <i class="bi bi-file-earmark-arrow-down" style="font-size: 1.2rem"></i>
            </button>
            <div class="d-flex flex-column">
              <h6 class="mb-1 text-sm text-dark">
                Notas - {{ nota.mes }}/{{ nota.ano }}</h6>
              <span class="text-xs">Emissão: {{ nota.mes }}/{{ nota.ano }} </span>
            </div>
          </div>
          <div class="text-sm d-flex align-items-center text-gradient text-dark font-weight-bold ms-auto"
            style="margin-right: 0.75rem !important;">
            R$ {{ Number(nota.valor_total_mes).toFixed(2) }}
          </div>
          <button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4" @click="ExibeModalNotas(nota.notas)">
            Exibir
          </button>
        </div>
        <div class="mt-3 mb-2" />
      </li>
    </ul>
  </div>
  <div v-else>
    <h6 class="mb-1 text-dark font-weight-bold text-sm">
      Você não possui notas!
    </h6>
  </div>
  <ExibeNotas :modal="exibeModalNotas" :notas="NotasSelecionadas" @close="FecharModal" />
  <Loading :active='isLoading' :is-full-page="fullPage" :loader='loader' :color="colorLoading" />
</template>

<script>
import ExibeNotas from "./ExibeNotas.vue";
import { api } from "../../boot/axios";
import { ref } from "vue";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import { useContratosStore } from "../../stores/contratos";
let ContratosStore;

export default {
  name: "RankingList",
  props: {
    title: {
      type: String,
      default: "",
    },
    item: {
      type: Array,
      default: () => { },
      title: String,
      date: String,
      amount: String,
      icon: String,
      color: String,
    },
    horizontalBreak: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Loading,
    ExibeNotas,
  },
  data() {
    return {
      ContratosStore,
      NotasSelecionadas: undefined,
      exibeModalNotas: false,
    }
  },
  methods: {
    async FecharModal() {
      this.exibeModalNotas = false;
    },
    ExibeModalNotas(notas) {
      this.exibeModalNotas = true;
      this.NotasSelecionadas = notas;
    },
    BaixarNota(nota) {
      this.isLoading = true
      api
        .post("contrato/imprimirnota", { "IdVenda": nota.id })
        .then((response) => {
          if (response.data.status == false) {
            console.log(response.data)
            //this.$notify({ type: "error", text: response.data.msg + " Entre em contato com o setor financeiro!", duration: 7000 });
          } else {
            if (response.data.Notabase64.toLowerCase().includes("fiscal")) {
              this.$notify({ type: "error", text: "Nota indisponível para download! Entre em contato com setor financeiro!", duration: 7000 });
            } else {
              var data = Uint8Array.from(atob(response.data.Notabase64), c => c.charCodeAt(0));
              const blob = new Blob([data], { type: "octet/stream" });
              const url = URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.download = nota.id + ".pdf";
              link.click();
              URL.revokeObjectURL(url);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
  },
  setup() {
    ContratosStore = useContratosStore();
    let isLoading = ref(false)
    let fullPage = true
    let loader = 'spinner'
    let colorLoading = '#007BFF'

    return {
      isLoading,
      fullPage,
      loader,
      colorLoading,
    }
  }
};
</script>
