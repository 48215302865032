<template>
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" tabindex="-1" aria-hidden="true"
    ref="modalRefPix">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Pix</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p class="text-sm text-body text-bold text-center mb-1">Código copiado para a área de transferência</p>
          <div class="qrcode" style="word-wrap: break-word;">
            <p class="text-xs text-body text-bold text-center mb-2">{{ this.urlQrCode }}</p>
          </div>
          <p class="text-sm text-body text-bold text-center">Mas se preferir, escaneie o qr code abaixo!</p>
          <div class="d-flex justify-content-center align-items-center">
            <qrcode-vue :value="urlQrCode" :size="tamanho" level="L" />
          </div>
        </div>
        <div class="modal-footer">
          <soft-button color="secondary" size="sm" @click="FecharModal">Fechar</soft-button>
        </div>
      </div>
      {{ AbrirBotao }}
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import bootstrap from 'bootstrap/dist/js/bootstrap';
import QrcodeVue from "qrcode.vue";
import { api } from "../../boot/axios";
import { ref } from 'vue'

export default {
  emits: ["close"],
  props: {
    boleto: Object,
    modal: Boolean,
  },
  components: {
    SoftButton,
    QrcodeVue
  },
  data() {
    return {
      tamanho: 260,
      urlQrCode: undefined,
    }
  },
  mounted() {
    this.modalNotas = new bootstrap.Modal(this.$refs.modalRefPix)
  },
  methods: {
    async AbrirModal() {
      await this.CopiarPix(this.boleto);
      this.modalNotas.show();
    },
    FecharModal() {
      this.modalNotas.hide();
      this.$emit("close");
    },
    async CopiarPix(boleto) {
      this.isLoading = true
      await api
        .post("financeiro/pixboleto", { "IdBoleto": boleto.id })
        .then((response) => {
          if (response.data.status == false) {
            this.$notify({ type: "error", text: response.data.msg + " Entre em contato com o setor financeiro!", duration: 7000 });
          } else {
            const tempElement = document.createElement('textarea');
            tempElement.value = response.data.DadosPix.qrCode.qrcode;
            this.urlQrCode = response.data.DadosPix.qrCode.qrcode;

            document.body.appendChild(tempElement);
            tempElement.select();
            document.execCommand('copy');
            document.body.removeChild(tempElement);
            this.$notify({ type: "success", text: "Código PIX copiado para área de transferência.", duration: 10000 });
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
  },
  computed: {
    AbrirBotao() {
      return this.modal ? this.AbrirModal() : ''
    }
  },
  setup() {
    const modalRefPix = ref(null);

    return {
      modalRefPix
    }
  }
}
</script>

<style scoped>
.qrcode {
  text-decoration: underline;
}

.qrcode:hover {
  cursor: pointer;
  text-decoration: none;
}
</style>